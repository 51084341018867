@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

body {
  background-image: url("../img/bg.png");
  background-size: cover;
}

#hero
{
  min-height: 100vh;
}

#hero > div {
  align-items: center;
  justify-content: center;
  display: flex;
}

#hero > div > div {
  width: 100%;
}

#hero2
{
  background: url("../img/1-SOL-CAR-Black.png"),
  url("../img/1-SOL-CAR-Silver.png"),
  url("../img/2-SOL-CAR-Green.png"),
  url("../img/2-SOL-CAR-Yellow.png"),
  url("../img/1-SOL-CAR-Solana-Colours.png"),
  url("../img/Camelot-Blue-Yellow-bg-remove.png");
  background-size: 33.33% 50%;
  background-position: left top, center top, right top, left bottom, center bottom, right bottom;
  background-repeat: no-repeat;

}

.mint
{
  background: grey;
  width: 150px;
  padding : 10px 35px;
}

.overlay
{
  min-height: 100vh;
  min-width: 100%;
}

nav
{
  margin-bottom: 50px;
}

.car-item
{
  min-height:100px;
  background-color: rgba(200,200,200,0.75);
  margin:25px;
  display: inline-block;

  box-shadow: 25px 26px 28px 4px rgba(34,215,217,0.32);
  -webkit-box-shadow: 25px 26px 28px 4px rgba(34,215,217,0.32);
  -moz-box-shadow: 25px 26px 28px 4px rgba(34,215,217,0.32);

}

.car-item:hover
{
  background-color: rgba(225,225,225,0.80);

  box-shadow: 25px 26px 28px 4px rgba(197,202,44,0.32);
  -webkit-box-shadow: 25px 26px 28px 4px rgba(197,202,44,0.32);
  -moz-box-shadow: 25px 26px 28px 4px rgba(197,202,44,0.32);

}

.car-description
{
  width: 100%;
  background: black;
  color: white;
  font-size: 20px;
  padding: 0 10px;
  text-align: center;
  border: 2px solid grey;
  margin: 0;
}

.car-description:hover
{
  color: gold;
}

li
{
  margin-left: 40px;
  font-size: 16px;
}

.parallax, .parallax2

{
  /* The image used */
  background-image: url("../img/Camelot-Red-Yellow .jpg");

  /* Set a specific height */
  min-height: 70vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax2
{
  background-image: url("../img/Camelot-Red-Solana.jpg");

}

.r
{
  color:white;
  width: auto;
  z-index: 1;
  display: inline-block;
  width: auto;
  height: auto;
  border: 1px solid white;
  border-radius: 50%;
  transition: font-size 3s;
  text-decoration: none;

}

.r:link
{
  color: white;
  text-decoration: none;
}

.r:hover
{

  color:blue;
  font-size:50px;

}

.line-double
{
  line-height: 2.0;
}

#roadmap
{
  background-image: url("../img/road.jpg");
  background-repeat: no-repeat;
  background-size: contain;

  min-height: 500px;
  font-size:22px;
  color: black;
}

@media only screen and (max-width: 600px)
{
  .parallax, .parallax2
  {
    display:none;
  }

  #hero2
  {
    background: url("../img/1-SOL-CAR-Black.png"),
    url("../img/1-SOL-CAR-Silver.png"),
    url("../img/2-SOL-CAR-Green.png");

    background-size: 100% 33%;
    background-position: center top, center center, center bottom;
    background-repeat: no-repeat;

  }

  #roadmap
  {
    background-image: none;
    min-height: 500px;
    font-size:20px;
    color: black;
  }
}

#connectPhantom {
  max-width: 200px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.nav-item {
  &--wallet {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.55);
    p {
      margin-bottom: 0;
    }
  }
}

.medium-icon {
  font-size: 18px;
  font-weight: bold;
}
